.root {
  display: flex;
  flex-direction: column;
  margin: 2rem;
  @media (--viewportMedium) {
    margin: 0;
    margin-top: 2rem;
  }
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCards {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCard {
  width: 100%;
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}
.header {
  border: 1px solid var(--matterColorAnti);
  padding: 0.5rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.totalItems {
  margin: 0;
  color: #858585;
  border-bottom: 1px solid var(--matterColorAnti);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: .5rem;
  margin-bottom: .5rem;
  margin-top: .75rem;
}
.buyBatch {
  margin-bottom: 0;
  margin-top: 0;
}
.discount {
  margin: 0;
  color: #686767;
}
.button {
  border: 1px solid var(--marketplaceColor);
  padding: 0.5rem 1.5rem;
  color: white;
  background-color: var(--marketplaceColor);
 
  &:hover {
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
  }
}
